import styled from 'styled-components';
// import { media } from '@styles';
const FractalTreeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: show;
`;

export default FractalTreeContainer;
